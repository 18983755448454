<template>
    <main role='main' class='page-content'>
        <Wizard v-if="!loading"></Wizard>
        <b-loading v-model='loading' :can-cancel='false' :is-full-page='true'></b-loading>
    </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'CreatePost',
    data() {
        return {
            loading: true
        }
    },
    methods: {
        ...mapActions('form', [
            'getPublicForm'
        ]),
        getInitialForm: function() {
            this.getPublicForm(this.$route.params.formId).then(() => {
              let post = {
                attributes: {}
              }
              this.$store.commit('post/setPost', post)
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        this.getInitialForm()
    },
    beforeDestroy() {
        this.$store.commit('post/setPost', null)
        this.$store.dispatch('map/reset')
    }
}
</script>
